import React, { useContext, useEffect, useState } from 'react'
import Link from 'components/Buttons/Link'
import TenantStr from 'components/TenantStr'
import { AppContext } from 'utils/context'
import { useTranslation, Trans } from 'react-i18next'
import { pivotalRefreshStatusToMsg } from 'utils/vars'

const Logout = () => {
  const { t } = useTranslation()
  const { authed, handleLogout, pivotalError } = useContext(AppContext)
  const [errorMsg, setErrorMsg] = useState("")
    
  useEffect(() => {
    if (authed) {
      handleLogout()
    }
  }, []) // eslint-disable-line
  
  useEffect(() => {
    if (pivotalError) {
      if (pivotalError.response.status) {
        setErrorMsg(pivotalRefreshStatusToMsg[pivotalError.response.status])
      }
    }
   }, [pivotalError]) // eslint-disable-line

  return (
    <div>
      <div className="SitePage">
        <div className="container">
          <h2>{t('Logged out')}</h2>
          <p>
            {errorMsg ?
              errorMsg :
              <Trans
                i18nKey="sessionExpiredMsg"
                components={{ productName: <TenantStr ProductName /> }}
              />
            }
          </p>
          <Link to="/" Pill>{t('Go Home')}</Link>
        </div>
      </div>
    </div>
  )
}

export default Logout
