import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Route, Switch, useLocation } from 'react-router-dom'
import OlarkWrapper from 'components/OlarkWrapper'
import FreshchatWrapper from 'components/FreshchatWrapper'
import CXOWrapper from 'components/CXOWrapper'
import TagManager from 'react-gtm-module'
import Header from 'components/Header'
import GlobalMessages from 'components/GlobalMessages'
import TenantComponent from 'components/TenantComponent'
import Footer from 'components/Footer'
import Site from './site'
import FullScreenSpinner from 'components/FullScreenSpinner'
import useApp from 'utils/useApp'
import Main from 'account/Main'
import Nav from 'components/Nav'
import { AppContext } from 'utils/context'
import { getTenantChatType } from 'utils/helpers'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

const App = () => {
  
  const { t, i18n } = useTranslation()
  const { pathname } = useLocation()
  
  const [appLoading, setAppLoading] = useState(true)

  const [dialogOpen, setDialogOpen] = useState('')
  const [accountPolicies, setAccountPolicies] = useState([])

  const {
      authed, handleLogout, setAuthed, tenant, showMaintenance, globalMessage,
      setGlobalMessage, previouslyFocused, setPreviouslyFocused, hasRequest, setHasRequest,
      refreshLoading, setRefreshLoading, sessionStart, setSessionStart, pivotalError, setPivotalError,
  } = useApp(appLoading, setAppLoading, setDialogOpen)

  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.classList.add('olark-visible')
   }, [pathname])

  useEffect(() => {
    if (tenant && tenant.gtmId) {
      TagManager.initialize({ gtmId: tenant.gtmId })
    }
    if (tenant && tenant.title) {
      document.title = tenant.title
      document.body.classList.add(tenant.slug)
    }
    if (tenant && tenant.Favicon) {
      const favicon = document.getElementById("favicon")
      if (favicon) favicon.href = tenant.Favicon
    }
    if (tenant && Object.keys(tenant).length) {
      if (!tenant.french_enabled) {
        i18n.removeResourceBundle('fr', 'translation')
        Cookies.set('i18next', 'en')
        if (i18n.language === 'fr') {
          window.location.reload();
        }
      }
    }
  }, [tenant]) // eslint-disable-line

  useEffect(() => {
    if (dialogOpen === 'logout-canceled') {
      setTimeout(() => setDialogOpen(''), 20 * 1000)
    }
  }, [dialogOpen])

  const state = {
    appLoading, setAppLoading, authed, setAuthed, tenant, showMaintenance,
    globalMessage, setGlobalMessage, handleLogout, accountPolicies, setAccountPolicies,
    dialogOpen, setDialogOpen, previouslyFocused, setPreviouslyFocused, setHasRequest,
    refreshLoading, setRefreshLoading, sessionStart, setSessionStart, pivotalError, setPivotalError,
  }

  const wrapperCls = classNames('Wrapper', {
    'loading': appLoading,
    'modalActive': dialogOpen,
    'globalMessage': globalMessage,
    'dialogOpen': dialogOpen,
  })
  
  const chatType = getTenantChatType(tenant)
  let liveChatWrapper = null
  if (chatType === 'olark') liveChatWrapper = <OlarkWrapper tenant={tenant} />
  else if (chatType === 'fresh') liveChatWrapper = <FreshchatWrapper />
  else if (chatType === 'cxo') liveChatWrapper = <CXOWrapper tenant={tenant} />

  const tenantReady = () => tenant && tenant.slug

  return (
    <AppContext.Provider value={state}>
      <a className="skip-link sr-only" href="#main">{t('Skip to main')}</a>
      {tenantReady() && (
        <div className={wrapperCls} id="top" style={tenant.style ? tenant.style : null}>
          <GlobalMessages page={pathname}/>
          <Header authed={authed} hasRequest={hasRequest} />
          <Switch>
            <Route path="/account" >
              <Main tenant={tenant}/>
            </Route>
            <Route path="/">
              <Site tenant={tenant} />
              <TenantComponent element={<Footer />} elementName="Footer" />
            </Route>
          </Switch>
        </div>
      )}
      {liveChatWrapper}
      <Nav fromDirection="left" />
      <FullScreenSpinner loading={appLoading} />
    </AppContext.Provider>
  )
}

export default App