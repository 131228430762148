import FieldError from 'forms/fields/FieldError'
import React from 'react'
import { useContext, useState, useEffect } from 'react'
import { FormContext } from 'utils/context'
import useField from 'forms/utils/useField'

const Acknowledgement = ({fieldName, description, confirmLabel}) => {

  const { data, setFieldValue, clearFieldError } = useContext(FormContext)
  const [confirm, setConfirm] = useState(false)
  const { error, onFocus, onBlur, } = useField(fieldName)

  useEffect(() => {
    if (data[fieldName] !== undefined && data[fieldName] !== null){ 
      setConfirm(data[fieldName])
      document.getElementById(fieldName).checked = true
    }
    setFieldValue(fieldName, confirm)
  }, []) // eslint-disable-line

  useEffect(() => {
    setFieldValue(fieldName, confirm)
  }, [confirm]) // eslint-disable-line

  const handleChange = () => {
    setConfirm(!confirm)
    clearFieldError(fieldName)
  }

  return (
    <div>
      <div className='margin-base-bottom'>{description}</div>
      <input 
        id={fieldName}
        name={fieldName}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={handleChange}
        type="checkbox"
      />
      <span>{confirmLabel}</span>
      <FieldError error={error} name={fieldName} />
    </div>
  )
}

export default Acknowledgement
