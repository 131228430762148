import { useEffect, useContext, useCallback } from 'react'
import useSWR, { useSWRConfig } from 'swr'
import { useLocation } from 'react-router-dom'
import { AppContext } from 'utils/context'
import api from 'utils/requests'

// fetching promises
const refreshFetcher = url => api.get(url, { validateStatus: (status) => status === 200}).then(r => r.data)
const sessionFetcher = url => api.get(url).then(r => r.data)

const useStatus = () => {

  let location = useLocation()

  const { cache } = useSWRConfig()

  const { handleLogout, sessionStart, setRefreshLoading, setDialogOpen, setPivotalError, } = useContext(AppContext)

  // SWR for pivotal data refresh
  const { data: statusData, error: statusError } = useSWR(`/users/status/?t=${sessionStart}`, refreshFetcher, {
    revalidateOnFocus: false,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      // 202 is 'pending', error on anything else
      if (error.response.status !== 202) return
      // retry for 15 seconds
      if (retryCount >= 15) return
      setTimeout(() => revalidate({ retryCount }), 1000)
    }
  })

  const refreshSession = useCallback(() => {
    api.get('/users/session/activity/')
    .catch(error => {
      if (error.message.includes('NS_BINDING_ABORTED')) {
        //Do nothing
      } else {
        handleLogout()
      }
    })
  }, [handleLogout])

  const rawRefreshSession = useCallback(() => {
    api.get('/users/session/activity/')
      .then(r => setDialogOpen('logout-canceled'))
      .catch(handleLogout)
  }, [handleLogout, setDialogOpen])

  // SWR for session data
  const { data: sessionData, error: sessionError } = useSWR(
    // triggers conditionally if refresh has completed
    (statusData && statusData.refresh_status === 'complete') ? `/users/session/status/?t=${sessionStart}` : null, sessionFetcher,
    // refetch data every 10 seconds
    { refreshInterval: 1000 * 10 },
  )

  useEffect(() => {
    cache.clear()
  }, [sessionStart, cache])

  useEffect(() => {
    if (sessionData && sessionData.time_remaining < 100) refreshSession()
  }, [sessionData, refreshSession])

  useEffect(() => {
    refreshSession()
  }, [location.pathname, refreshSession])

  useEffect(() => {
    if (sessionData && sessionData.time_remaining) {
      setRefreshLoading(false)
    }
  }, [statusData, sessionData, setRefreshLoading, sessionStart])

  useEffect(() => {
    if (sessionError) handleLogout()
  }, [sessionError]) // eslint-disable-line 

  useEffect(() => {
    if (statusError) setPivotalError(statusError)
  }, [statusError]) // eslint-disable-line 

  return {
    remaining: sessionData ? sessionData.time_remaining : null,
    error: statusError || sessionError,
    refreshSession,
    rawRefreshSession,
  }
}

export default useStatus