import { useState, useCallback, useEffect, useRef } from 'react'
import * as Sentry from '@sentry/browser'
import { getTime } from 'date-fns'
import { useHistory, useLocation } from 'react-router-dom'
import { getRequest } from 'utils/requests'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

const useApp = (appLoading, setAppLoading, setMenuActive) => {

  const { push } = useHistory()
  const location = useLocation()
  const { i18n } = useTranslation()

  const sessionStart = useRef(null)
  const previouslyFocused = useRef(null)

  const [authed, setAuthed] = useState(null)
  const [accountError, setAccountError] = useState(false)
  const [hasRequest, setHasRequest] = useState(null)
  const [tenant, setTenant] = useState({})
  const [showMaintenance, setShowMaintenance] = useState(null)
  const [globalMessage, setGlobalMessage] = useState(null)
  const [refreshLoading, setRefreshLoading] = useState(true)
  const [pivotalError, setPivotalError] = useState(null)

  const handleLogout = () => {
    setRefreshLoading(true)
    getRequest('/api/users/logout/')
      .then(res => {
        setMenuActive(false)
        setAuthed(false)
        setGlobalMessage(null)
        setAppLoading(false)
        Cookies.remove('user_id')
        Cookies.remove('sessionid')
        Cookies.remove('impersonated')
        Cookies.remove('sessionid')
        push('/logout')
      })
      .catch(console.log)
  }

  const handleTenantData = useCallback(tenantRes => {
    if (tenantRes.slug) {
      import(`tenants/${tenantRes.slug}/TenantData`)
        .then(data => {
          setTenant({
            ...data.default(),
            ...tenantRes,
          })
          setAppLoading(false)
        })
        .catch(err => {
          setAppLoading(false)
          Sentry.captureException(err)
        })
    }
  }, [setAppLoading])

  const initializeTenant = useCallback(() => {
    getRequest('/api/health/')
      .catch(err => {
        setShowMaintenance(true)
      })
    getRequest('/api/tenant/')
      .then(res => handleTenantData(res))
      .catch(err => Sentry.captureException(err))

  }, [handleTenantData])

  const setSessionStart = () => sessionStart.current = String(getTime(new Date()))

  const checkAuthed = () => {
    const user = Cookies.get('user_id')
    if (user) {
      setSessionStart()
      setAuthed(true)
    }
  }

  const setPreviouslyFocused = elemId => {
    previouslyFocused.current = elemId
  }

  useEffect(() => {
    initializeTenant()
    checkAuthed()
  }, []) // eslint-disable-line
  
  useEffect(() => {
    initializeTenant()
  }, [i18n.language]) // eslint-disable-line

  useEffect(() => {
    if (authed && !appLoading) {
      if (authed && !location.pathname.includes('account')) {
        push('/account')
      }
    }
  })

  useEffect(() => {
    const path = location.pathname.split('/')[1]
    document.body.classList.add(path ? path : 'home')
    return () => document.body.classList.remove(path ? path : 'home')
  }, [location.pathname])

  const textTransformProduct = () => {
    const modded = { ...tenant }
    if (tenant.product_name) {
      const product = tenant.product_name
      modded.product_name = <span className="tenant-product-name"><i>{product.charAt(0)}</i>{product.substring(1)}</span>
    }
    return modded
  }

  return {
    tenant: textTransformProduct(),
    authed, handleLogout, setAuthed,
    showMaintenance, globalMessage, setGlobalMessage,
    previouslyFocused, setPreviouslyFocused, hasRequest,
    setHasRequest, accountError, setAccountError,
    refreshLoading, setRefreshLoading,
    sessionStart: sessionStart.current,
    setSessionStart, pivotalError, setPivotalError,
  }
}

export default useApp