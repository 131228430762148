import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import { AccountContext, FormContext } from 'utils/context'
import { genderChoices, reasonChoices, relationshipChoices, cancellationChoices } from 'forms/utils/vars'
import RequesterDetails from 'forms/fieldsets/general/RequesterDetails'
import PolicyChangesConfirm from 'forms/fieldsets/general/PolicyChangesConfirm'
import OtherDriversConfirm from 'forms/fieldsets/general/OtherDriversConfirm'
import LicensingConfirm from './LicensingConfirm'
import RequestDisclaimer from 'components/RequestDisclaimer'
import { prettyDate, titleCase, vehicleName } from 'utils/helpers'
import { maritalChoices } from 'forms/utils/vars'
import { useTranslation } from 'react-i18next'

const DriverAddConfirm = ({ province }) => {

  const { t } = useTranslation()
  const { vehicles } = useContext(AccountContext)
  const { data } = useContext(FormContext)
  let fName = data['first_name']

  const marital = maritalChoices.find(m => m[0] === data['marital_status'])

  let relationshipStatus = relationshipChoices.filter(arr => arr.includes(data['relationship_status'])).flatMap(arr => arr)
  let genderChoice = gender => {return genderChoices.filter(arr => arr.includes(gender)).flatMap(arr => arr)}

  return (
    <fieldset>
      <RequestDisclaimer />
      <RequesterDetails />
      <ConfirmCard title={`${titleCase(fName) || 'Driver'}'s Information`} slug="driver-information" id={data.id}>
        <ul className="InfoList">
          <li>
            <small>{t('Licence number')}</small>
            <span className="uppercase">{data['drivers_licence_number']}</span>
          </li>
          <li>
            <small>{t('Gender')}</small>
            {genderChoice(data['gender'])[1]}
          </li>
          <li>
            <small>{t('Date of birth')}</small>
            {prettyDate(data['date_of_birth'])} 
          </li>
          <li>
            <small>{t('Relationship to driver')}</small>
            {relationshipStatus[0] === 'other' ? `${relationshipStatus[1]} - ${data['relationship_status_other']}` : relationshipStatus[1]}
          </li>
          <li>
            <small>{t('Out of province history')}</small>
            {data['out_of_province_history'] ? t('Yes') : t('No')}
          </li>
          <li>
            <small>{t('Marital status')}</small>
            {marital[1]}
          </li>
          <li>
            <small>{t('Vehicle that would be driven most often')}</small>
            {vehicleName(data['vehicle_assignment'], vehicles)}
          </li>
          <li>
            <small>{t('Additional details provided')}</small>
            {data['additional_information'] ? data['additional_information'] : 'None'}
          </li>
        </ul>
      </ConfirmCard>
      <ConfirmCard title={t("{{name}}'s Licensing", {name: titleCase(fName)}) || t("Driver's Licensing")} slug="driver-licensing" id={data.id}>
        <LicensingConfirm licences={data.licences} province={province} />
        {data['primary_training_date'] &&
          <>
          <strong>{t('Driver trained at:')}</strong> {data['training_school']}<br />
          <strong>{t('Driver training completed:')}</strong> {prettyDate(data['primary_training_date'])}<br /></>
        }
      </ConfirmCard>
      <ConfirmCard title={t("{{name}}'s Suspension History", {name: titleCase(fName)}) || t("Driver's History")} slug="driver-history" id={data.id}>
        {data['auto_fraud'] ? t('The driver has been convicted of auto fraud') : t('The driver has not been convicted of auto fraud')}
        <hr />
        {data.suspensions.length === 0 && t('No suspensions added')}
        <ul className="SuspensionListConfirm">
          {data.suspensions.map(s => {
            let reason = reasonChoices.find(r => r[0] === s.reason)
            return (
              <li key={s.reason}>
                {reason[2]} <small>{prettyDate(s.suspension_date, true)} - {prettyDate(s.reinstatement_date, true)}</small>
              </li>
            )
          })}
        </ul>
      </ConfirmCard>
      <ConfirmCard title={t("{{name}}'s Insurance History", {name: titleCase(fName)}) || t("Insurance History")} slug="insurance-history" id={data.id}>
        {Number(data['has_cancellations']) ? t('The driver has cancelled insurance policies') : t('The driver does not have cancelled insurance policies')}
        <hr />
        {data.cancellations.length === 0 && t('No cancellations added')}
        <ul className="CancellationListConfirm">
          {data.cancellations.map(s => {
            let reason = cancellationChoices.find(r => r[0] === s.cancellation_reason)
            return (
              <li key={s.cancellation_reason}>
                {reason[1]} <small>{prettyDate(s.cancellation_date, true)} {s.insurance_reinstatement_date ? "-" : ""} {prettyDate(s.insurance_reinstatement_date, true)}</small>
              </li>
            )
          })}
        </ul>
      </ConfirmCard>
      <PolicyChangesConfirm />
      <OtherDriversConfirm />
      <ConfirmCard title={t("Consent")} slug="consent" id={data.id}>
        {data['consent'] ?
          t('You have provided consent to run reports on the driver information provided.') :
          t('You have not provided consent to run reports on the driver information provided.')
        }
      </ConfirmCard>
    </fieldset>
  )
}

export default DriverAddConfirm