import React from 'react'
import { FormContext } from 'utils/context'
import { startOfDay, addDays } from 'date-fns'
import DateChooser from 'forms/fields/DateChooser'
import { useTranslation } from 'react-i18next'
import RemoveReason from './RemoveReason'

const VehicleReplaceRemoveReason = () => {
  const { t } = useTranslation()

  return (
    <fieldset>
      <RemoveReason/>
      <DateChooser
        name="loss_of_possession_date"
        label={t("When will you no longer have possession of this vehicle?")}
        minDate={startOfDay(new Date())}
        maxDate={addDays(new Date(), 30)}
      />
    </fieldset>
  )
}

export default VehicleReplaceRemoveReason